import httpClient from '@/libs/http-client'

// commons
const nullToQuote = (data) => {
  return !data ? '' : data
}

export function signIn (params) {
  const url = '/api/admin/login'
  // params.password = encrypt(params.password, params.username)

  return httpClient.post(url, params).then(response => {
    return response.data
  })
}
export function memLogout (data) {
  const url = '/api/admin/member/memLogout'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function memberJoin (data) {
  const url = '/api/admin/member/join'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function loginList (data) {
  const url = '/api/admin/member/loginList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function memberStatusList (data) {
  const url = '/api/admin/member/statusList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 회원입출금내역
export function memberCashList (data) {
  const url = '/api/admin/cash/cashList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 상위유저 변경
export function recUpdate (data) {
  const url = '/api/admin/member/recUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 롤링관리
export function rollMemList (data) {
  const url = '/api/admin/member/rollMemList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 다중 접속 아이피
export function multipleUsers (data) {
  const url = '/api/admin/member/ipList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 다중 접속 아이피
export function multipleDetail (data) {
  const url = '/api/admin/member/ipDetailList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 사용자 추적 아이피
export function userTracking (data) {
  const url = '/api/admin/member/logList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 유저 보유금액
export function memberTotalList (data) {
  const url = '/api/admin/cash/totalList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function signupFast (data) {
  const url = '/api/admin/member/signupFast'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getToken () {
  const url = '/api/admin/getToken'
  return httpClient.post(url).then(response => {
    return response.data
  })
}

export function getCode (masterCode, groupCode, code) {
  const url = '/api/common/code'

  const data = {
    masterCode: nullToQuote(masterCode),
    groupCode: nullToQuote(groupCode),
    code: nullToQuote(code)
  }

  return httpClient.post(url, data).then(response => {
    return response.data.data.codes
  })
}

export function getGameCodes (masterCode, groupCode, code, siteId) {
  const url = '/api/admin/gameCodes'

  const data = {
    masterCode: nullToQuote(masterCode),
    groupCode: nullToQuote(groupCode),
    code: nullToQuote(code),
    siteId
  }

  return httpClient.post(url, data).then(response => {
    return response.data.data.codes
  })
}

// 회원 목록
export function memberList (data) {
  const url = '/api/admin/member/memberList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 하위회원 목록
export function memberTreeList (data) {
  const url = '/api/admin/member/treeList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 회원 정보 수정
export function memStatusChange (data) {
  const url = '/api/admin/member/updateStatus'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 회원 정보 수정
export function memberModify (data) {
  const url = '/api/admin/member/modify'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function memberLockBottom (data) {
  const url = '/api/admin/member/userLockWithBottom'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 공회전 직하부 저장
export function memberupdateBlank (data) {
  const url = '/api/admin/member/updateBlankSpinRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 공회전 하부전체 저장
export function memberupdateAllBlank (data) {
  const url = '/api/admin/member/updateAllBlankSpinRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 회원 상세 정보
export function memberDetail (data) {
  const url = '/api/admin/member/detail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 파트너 목록
export function companyList (data) {
  const url = '/api/admin/partner/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 상세 정보
export function companyDetail (data) {
  const url = '/api/admin/partner/detail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 트리
export function companyTreeList (data) {
  const url = '/api/admin/partner/treeList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 입출금내역
export function cashList (data) {
  const url = '/api/admin/cash/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 입출금 상태 수정
export function cashStatusChange (data) {
  const url = '/api/admin/cash/updateStatus'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 베팅 목록
export function betList (data) {
  const url = '/api/admin/bet/betlist'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function headerTopInfo (data) {
  const url = '/api/admin/headTopInfo'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 상단 실시간 데이터 처리
export function headerCashInfo (data) {
  const url = '/api/admin/headCashInfo'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 회원 요율 조회
export function memberRate (data) {
  const url = '/api/admin/member/rate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 회원 요율 업데이트
export function memberRateUpdate (data) {
  const url = '/api/admin/retail/updateRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function memberNewRateUpdate (data) {
  const url = '/api/admin/member/newUpdateRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function settingCashInOutYN (data) {
  const url = '/api/admin/member/settingCashInOut'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 회원 요율 업데이트
export function partnerBotMemberRateUpdate (data) {
  const url = '/api/admin/retail/updateAllBottomRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// PT
export function creditChargeApply (data) {
  const url = '/api/admin/credit/deposit'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function creditWithdrawApply (data) {
  const url = '/api/admin/credit/withdraw'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function creditChargeApplySubPoint (data) {
  const url = '/api/admin/credit/useSubPoint'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// PT 요청 리스트
export function creditApplyList (data) {
  const url = '/api/admin/partner/credit/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// PT 요청 승인
export function creditUpdateStatus (data) {
  const url = '/api/admin/credit/updateStatus'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 유저 포인트 관리
export function memberPointList (data) {
  const url = '/api/admin/point/pointList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 유저 포인트 지급회수
export function memberPointInOut (data) {
  const url = '/api/admin/point/pointInOut'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 회원삭제
export function memberDelete (data) {
  const url = '/api/admin/member/delte'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 출금정지
export function memberCashStop (data) {
  const url = '/api/admin/member/updateOutAmtYn'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 롤링관리 삭제
export function rollMemUpdate (data) {
  const url = '/api/admin/member/rollMemUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 관리자 입금
export function cashIn (data) {
  const url = '/api/admin/cash/in'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 관리자 출금
export function cashOut (data) {
  const url = '/api/admin/cash/out'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 관리자 메모
export function setMemberMemo (data) {
  const url = '/api/admin/member/updateMemListIcon'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 관리자 패스워드
export function setMemberResetPassword (data) {
  const url = '/api/admin/member/resetPassword'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function setMemberDenyIP (data) {
  const url = '/api/admin/member/deniedLoginIp'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 보고서
// 회원승패
export function reportWl (data) {
  const url = '/api/admin/report/memWL'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 기간별 현황
export function reportTerm (data) {
  const url = '/api/admin/report/period'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 게임별 리포트
export function reportGameType (data) {
  const url = '/api/admin/report/gameType'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 이자별 리포트
export function reportDaily (data) {
  const url = '/api/admin/report/day'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 거래내역조회
export function reportTrade (data) {
  const url = '/api/admin/report/trade'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 유지보수 정보
export function reportAs (data) {
  const url = '/api/admin/report/as'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 월간 지불 보고서
export function reportMonthly (data) {
  const url = '/api/admin/report/pay'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 보유머니 현황 파트너
export function reportCompany (data) {
  const url = '/api/admin/report/partner'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 게임 useYn리스트
export function getGameUseYn (data) {
  const url = '/api/admin/bet/gameUse'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 게임 useYn업데이트
export function updateGameUseYn (data) {
  const url = '/api/admin/bet/gameUseUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 고객센터관리
export function boardList (boardType, data) {
  const url = `/api/admin/board/${boardType}/list`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function boardSave (boardType, data) {
  const url = `/api/admin/board/${boardType}/save`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function boardDetail (boardType, data) {
  const url = `/api/admin/board/${boardType}/datail`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 쪽지 리스트
export function msgList (boardType, data) {
  const url = `/api/admin/board/${boardType}`
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 쪽지 발송
export function msgSend (data) {
  const url = '/api/admin/board/msgSend'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 쪽지 발송
export function msgUpdate (data) {
  const url = '/api/admin/board/msgUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 상단슬라이드발송
export function msgHeadSave (data) {
  const url = '/api/admin/board/msgHeadSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 쪽지 발송
export function msgJoinSave (data) {
  const url = '/api/admin/board/msgJoinSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 사이트 옵션
export function siteOption (data) {
  const url = '/api/admin/setting/option'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 사이트 옵션 저장
export function siteOptionSave (data) {
  const url = '/api/admin/setting/optionSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 사이트 배너
export function frontBanner (data) {
  const url = '/api/admin/setting/banner'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 사이트 배너 저장
export function frontBannerSave (data) {
  const url = '/api/admin/setting/bannerSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 사이트 업체 은행
export function comBank (data) {
  const url = '/api/admin/setting/bank'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 사이트 업체 은행 저장
export function comBankSave (data) {
  const url = '/api/admin/setting/bankSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 레벨별 도메인
export function levelDomain (data) {
  const url = '/api/admin/setting/level/domain'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 레벨별 도메인 저장
export function levelDomainSave (data) {
  const url = '/api/admin/setting/level/domainSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 레벨별 베팅한도
export function levelBetLimit (data) {
  const url = '/api/admin/setting/level/betLimit'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 레벨별 베팅한도 저장
export function levelBetLimitSave (data) {
  const url = '/api/admin/setting/level/betLimitSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 레벨별 계좌
export function levelAccount (data) {
  const url = '/api/admin/setting/level/account'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 캐시이동내역
export function cashSendList (data) {
  const url = '/api/admin/member/cashSendlist'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 관리자 지급 /회수
export function adminCashPointList (data) {
  const url = '/api/admin/cash/adminCashPointList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function cashTransLog (data) {
  const url = '/api/admin/member/cashTransLog'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getUserTop (data) {
  const url = '/api/admin/member/baseRecommenderId'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function resetPassword (data) {
  const url = '/api/admin/member/resetPassword'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getGameRollingList (data) {
  const url = '/api/admin/member/rollingList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getRateCalculate (data) {
  const url = '/api/admin/cash/listCalculate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getUserCashAmtAPI (data) {
  const url = '/api/admin/member/cashAmt'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerLevelUp (data) {
  const url = '/api/admin/member/partnerLevelUp'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function setMoneyMerge (data) {
  const url = '/api/admin/member/changeMemCashFlag'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function setUserBonusSetting (data) {
  const url = '/api/admin/member/bonusSetting'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getMultipleRateInfo (data) {
  const url = '/api/admin/member/multipleRateInfo'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function setMultipleRateInfo (data) {
  const url = '/api/admin/member/updateMultipleRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function memberListTree (data) {
  const url = '/api/admin/member/hierarchy'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function memberListByPartner (data) {
  const url = '/api/admin/member/memberTreeList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function memberToAdminPoint (data) {
  const url = '/api/admin/point/pointChg'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function memberBotPointList (data) {
  const url = '/api/admin/point/botRateList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function memberAbuseInit (data) {
  const url = '/api/admin/member/abuseLockReset'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
